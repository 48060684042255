<template>
    <v-container class="pa-0">
        <v-row>
            <v-col 
                v-if="propertiesAreSelectable"
                class="py-0"
                cols="1" 
            >
                <h3 v-if="subheader">⠀</h3> <!-- empty char for spacing -->
                <v-checkbox
                    v-model="propertyIsSelected"
                    hide-details
                    class="ma-0"
                ></v-checkbox>
            </v-col>
            <v-col class="pt-0 pb-2 align-self-center">
                <v-form
                    ref='form'
                    v-model="valid"
                >
                    <!-- Subheader -->
                    <h3 
                        v-if="subheader"
                        class="pb-1"
                    >
                        {{ translate(subheader) }}
                    </h3>

                    <!-- Hint -->
                    <Dialog 
                        v-if="property.hint" 
                        v-model="showHint" 
                        :title="$t('Hint')"
                    >
                        {{ translate(property.hint)}}
                    </Dialog>
                    
                    <!-- Input -->
                    <component 
                        :is="`${property.input_type}_input`"
                        :featureName="featureName"
                        :propertyGroupName="propertyGroupName" 
                        :propertyName="propertyName"
                        :property="property"
                        :dirty="dirty" 
                        @showHint="showHint = true"
                        @undo="undoChange"
                        :class="isMobileSize() ? 'pb-3' : 'pb-4'"
                    />
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Dialog from '@/components/dialogs/Dialog.vue'
import NUMBER_input from '@/components/inputs/Number.vue'
import PASSWORD_input from '@/components/inputs/Password.vue'
import TEXT_input from '@/components/inputs/Text.vue'
import SELECT_input from '@/components/inputs/Select.vue'
import SWITCH_input from '@/components/inputs/Switch.vue'
import SLIDER_input from '@/components/inputs/Slider.vue'
import TIME_V1_input from '@/components/inputs/Time_V1.vue'
import TIME_V2_input from '@/components/inputs/Time_V2.vue'
import { isEqual } from 'lodash'

export default {
    components: {
        Dialog,
        NUMBER_input,
        PASSWORD_input,
        TEXT_input,
        SELECT_input,
        SWITCH_input,
        SLIDER_input,
        TIME_V1_input,
        TIME_V2_input
    },
    props: {
        featureName: {
            type: String,
            required: true
        },  
        propertyGroupName: {
            type: String,
        },
        propertyName: {
            type: String,
            required: true
        },
        property: {
            type: Object,
            required: true
        },
        propertiesAreSelectable: {
            type: Boolean,
            default: false
        },
        previousSubheader: {
            type: Object,
            default: undefined
        }
    },
    data: () => ({
        showHint: false,
        valid: true,
    }),
    methods: {
        undoChange() {
            let payload = {
                featureName: this.featureName,
                propertyGroupName: this.propertyGroupName,
                propertyName: this.propertyName,
            }
            this.$store.dispatch("restoreProperty", payload)
        }
    },
    computed: {
        dirty() {
            return this.$store.getters.dirty(this.featureName, this.propertyGroupName, this.propertyName)
        },
        propertyIsSelected: {
            get() {
                return this.$store.getters.storeEditorPropertyIsSelected(this.featureName, this.propertyGroupName, this.propertyName)
            },
            set() {
                let payload = {
                    featureName: this.featureName,
                    propertyGroupName: this.propertyGroupName,
                    propertyName: this.propertyName,
                }
                this.$store.dispatch("toggleSelectedProperty", payload)
            }
        },
        subheader() {
            if(!this.property.subheader || isEqual(this.property.subheader, this.previousSubheader)) return undefined
            return this.property.subheader
        }
    },
    watch: {
        valid(newValue) {
            this.$refs.form.validate()
            let payload = {
                featureName: this.featureName,
                propertyGroupName: this.propertyGroupName,
                propertyName: this.propertyName,
            }
            // If it changes from invalid to valid
            if(newValue) {
                this.$store.dispatch("setPropertyValid", payload)
            }
            // If it changes from valid to invalid
            else {
                this.$store.dispatch("setPropertyInvalid", payload)
            }
        }
    }
}
</script>