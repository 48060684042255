import { forOwn } from "lodash"
import i18n from '@/i18n'
import translate from "@/utils/translate"
import formatFloat from "./formatFloat"

// Returns an array of functions that specifies the Vuetify input rules of a property
export default function generateRules(property) {
    let rules = []
    let maxValue
    let minValue
    let maxLength
    let minLength
    let nullable 
    let advanced

    let dataTypePrefix = property.data_type.replace(/[0-9]/g, '')
    let dataTypeValue = parseInt(property.data_type.replace(dataTypePrefix, ''))
    // Set rules based on data type
    switch(dataTypePrefix) {
        case 'int':
            maxValue = Math.pow(2, dataTypeValue-1)-1
            minValue = -Math.pow(2, dataTypeValue-1)
            break; 
        case 'uint':
            maxValue = Math.pow(2, dataTypeValue)-1
            minValue = 0
            break;  
        case 'hex':
            maxLength = property.length
            minLength = 0
            break;  
        case 'char':
            maxLength = property.length
            minLength = 0
            break;  
    }

    // Overwrite previous rules based on spec if necessary
    forOwn(property.rules, function(ruleValue, ruleName) {
        switch(ruleName) {
            case 'min_value':
                if(minLength == null || ruleValue > minValue) { minValue = ruleValue }
                break;
            case 'max_value':
                if(maxValue == null || ruleValue < maxValue) { maxValue = ruleValue }
                break;
            case 'min_length':
                if(minLength == null || ruleValue > minLength) { minLength = ruleValue }
                break;
            case 'max_length':
                if(maxLength == null || ruleValue < maxLength) { maxLength = ruleValue }
                break;
            case 'nullable':
                nullable = ruleValue
                break;
            case 'advanced':
                advanced = ruleValue
                break;
        }
    })

    // Scale numeric values
    let scalingFactor = property.scaling_factor ?? 1
    if(minValue != null) { minValue = formatFloat(minValue * scalingFactor, scalingFactor) }
    if(maxValue != null) { maxValue = formatFloat(maxValue * scalingFactor, scalingFactor) }

    // Double length requirements if data type == hex (2 nibbles per byte)
    if(minLength != null && dataTypePrefix == 'hex') { minLength *= 2 }
    if(maxLength != null && dataTypePrefix == 'hex') { maxLength *= 2 }

    // Generate the actual rules
    if(!nullable) {
        rules.push(v => (v !== null && v !== '')  || i18n.t('Rules.nullable'))  // Disallows empty or null input
    }
    if(dataTypePrefix == 'hex') {
        rules.push(v => /^[0-9A-F]*$/.test(v) || i18n.t('Rules.hex'))  // Requires hexadecimal input 
    }   
    if(dataTypePrefix == 'char') {
        rules.push(v => /^[ -~]*$/.test(v) || i18n.t('Rules.char'))  // Requires ASCII input
    }
    if(minValue != null) {
        rules.push(v => ((nullable && (v == null || v === '')) || v >= minValue) || i18n.t('Rules.minValue', [minValue]))  // Value must be greater or equal to minValue
    }
    if(maxValue != null) {
        rules.push(v => ((nullable && (v == null || v === '')) || v <= maxValue) || i18n.t('Rules.maxValue', [maxValue]))  // Value must be smaller or equal to maxValue
    }
    if(minLength != null) {
        rules.push(v => ((nullable && (v == null || v === '')) || v.length >= minLength) || i18n.t('Rules.minLength', [v.length, minLength]))  // Value must be at least minLength characters long
    }
    if(maxLength != null) {
        rules.push(v => ((nullable && (v == null || v === '')) || v.length <= maxLength) || i18n.t('Rules.maxLength', [v.length, maxLength]))  // Value must be at most maxLength characters long
    }
    if(advanced != null) {
        rules.push(v => ((nullable && (v == null || v === '')) || new RegExp(advanced.regex).test(v)) || translate(advanced.message)) // Value must match regex
    }
    return rules
}
