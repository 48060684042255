<template>
    <div>
        <span class="pa-0">
            {{ translate(property.title) }}
        </span>
        <v-slider
            v-model="configProperty"
            :min="0"
            :max="keys.length-1"
            step=1
            :ticks="keys.length <= 16 ? 'always' : false"
            tick-size="4"
            hide-details='auto'
        >
            <template v-slot:append>
                <UndoButton v-show="dirty" v-on="$listeners" />
                <HintButton v-if="property.hint" v-on="$listeners" />
            </template>
            <template v-slot:prepend>
                {{value}}
            </template>
        </v-slider>
    </div>
</template>

<script>
import HintButton from '@/components/inputs/subcomponents/HintButton.vue'
import UndoButton from '@/components/inputs/subcomponents/UndoButton.vue'
export default {
    components: { 
        HintButton, 
        UndoButton
    },
    props: {
        featureName: {
            type: String,
            required: true
        },  
        propertyGroupName: {
            type: String,
        },
        propertyName: {
            type: String,
            required: true
        },
        property: {
            type: Object,
            required: true
        },
        dirty: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        keys() {
            return Object.keys(this.property.options).map(Number)
        },
        value() {
            let key = this.keys[this.configProperty]
            let value = this.property.options[key]
            if(typeof value == 'object') {
                value = this.translate(value)
            }
            if(this.property.unit) {
                value += this.property.unit
            }
            return value.toString()
        },
        configProperty: {
            get() {
                return this.$store.getters.property(this.featureName, this.propertyGroupName, this.propertyName)    
            },
            set(newValue) {
                let payload = {
                    featureName: this.featureName,
                    propertyGroupName: this.propertyGroupName,
                    propertyName: this.propertyName,
                    newValue
                }
                this.$store.dispatch("setProperty", payload)
            }
        }
    }
}
</script>