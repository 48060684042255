<template>
    <v-text-field
        v-model="propertyValue"
        :label="translate(property.title)"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        hide-details="auto"
        filled
        @keydown.enter.prevent
    >
        <template v-slot:append-outer>
            <UndoButton v-show="dirty" v-on="$listeners" class="mt-4"/>
            <HintButton v-if="property.hint" v-on="$listeners" class="mt-4"/>
        </template>
    </v-text-field>
</template>

<script>
import HintButton from '@/components/inputs/subcomponents/HintButton.vue'
import UndoButton from '@/components/inputs/subcomponents/UndoButton.vue'
export default {
    components: { 
        HintButton, 
        UndoButton
    },
    props: {
        featureName: {
            type: String,
            required: true
        },  
        propertyGroupName: {
            type: String,
        },
        propertyName: {
            type: String,
            required: true
        },
        property: {
            type: Object,
            required: true
        },
        dirty: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        propertyValue: {
            get() {
                return this.$store.getters.property(this.featureName, this.propertyGroupName, this.propertyName)    
            },
            set(newValue) {
                let payload = {
                    featureName: this.featureName,
                    propertyGroupName: this.propertyGroupName,
                    propertyName: this.propertyName,
                    newValue
                }
                this.$store.dispatch("setProperty", payload)
            }
        }
    },
    data:() => ({
        showPassword: false
    }),
}
</script>