<template>
    <v-input hide-details>
        <v-row>
            <v-col cols=12 md=8 :class="isMobileSize() ? '' : 'pr-0'">
                <span class="pa-0">
                    {{ translate(property.title) }}
                </span>
                <v-slider
                    v-model="value"
                    type="number"
                    :rules="rules"
                    hide-details='auto'
                    min="0"
                    max="63"
                    step="1"
                >
                    <template v-slot:prepend>
                        {{timer}}
                    </template>
                </v-slider>
            </v-col>
            <v-col :class="isMobileSize() ? '' : 'pl-0'">
                <v-select
                    v-model="step"
                    :items="steps"
                    item-text="text"
                    item-value="val"
                    hide-details
                    :class="isMobileSize() ? 'pt-0' : ''"
                    filled
                    :menu-props="{ top: false, offsetY: true}"
                >
                    <template v-slot:prepend-item>
                        <b class="px-4">{{$t('Input.time.step')}}</b>
                    </template>
                    <template v-slot:append-outer>
                        <UndoButton v-show="dirty" v-on="$listeners" class="mt-4"/>
                        <HintButton v-if="property.hint" v-on="$listeners" class="mt-4"/>
                    </template>
                    <template v-slot:append>
                        <v-icon>mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </v-col>
        </v-row>
    </v-input>
</template>

<script>
import HintButton from '@/components/inputs/subcomponents/HintButton.vue'
import UndoButton from '@/components/inputs/subcomponents/UndoButton.vue'
export default {
    components: { 
        HintButton, 
        UndoButton
    },
    props: {
        featureName: {
            type: String,
            required: true
        },  
        propertyGroupName: {
            type: String,
        },
        propertyName: {
            type: String,
            required: true
        },
        property: {
            type: Object,
            required: true
        },
        dirty: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        rules() {
            return [
                v => (v !== null && v !== '')  || this.$t('Rules.nullable'),
                v => v >= 0 || this.$t('Rules.minValue', [0]),
                v => v <= 127 || this.$t('Rules.maxValue', [127])
            ]
        },
        value: {
            get() {
                return this.$store.getters.property(this.featureName, this.propertyGroupName, this.propertyName) % 64
            },
            set(newValue) {
                let payload = {
                    featureName: this.featureName,
                    propertyGroupName: this.propertyGroupName,
                    propertyName: this.propertyName,
                    newValue: newValue + this.step.val * 64
                }
                this.$store.dispatch("setProperty", payload)
            }
        },
        step: {
            get() {
                let val = Math.floor(this.$store.getters.property(this.featureName, this.propertyGroupName, this.propertyName) / 64)
                return this.steps[val]
            },
            set(newValue) {
                let payload = {
                    featureName: this.featureName,
                    propertyGroupName: this.propertyGroupName,
                    propertyName: this.propertyName,
                    newValue: this.value + newValue * 64
                }
                this.$store.dispatch("setProperty", payload)
            }
        },
        steps() {
            return [
                {
                    text: '1s',
                    multiplier: 1,
                    val: 0
                }, 
                {
                    text: '20s',
                    multiplier: 20,
                    val: 1
                },
                {
                    text: '60m 40s',
                    multiplier: 400,
                    val: 2
                },
                {
                    text: '2h 13m 20s',
                    multiplier: 8000,
                    val: 3
                }
            ]
        },
        timer() {
            let total_seconds = this.value * this.step.multiplier 
            let hours = Math.floor(total_seconds / 3600)
            let minutes = Math.floor((total_seconds / 60) % 60)
            let seconds = total_seconds % 60
            return [hours, minutes, seconds].map(v => v < 10 ? "0" + v : v).join(":")
        }
    }
}
</script>