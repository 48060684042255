<template>
    <v-input hide-details>
        <v-row>
            <v-col cols=5 sm=8 lg=8 class="pr-0">
                <v-text-field
                    v-model="value"
                    :label="translate(property.title)"
                    type="number"
                    :rules="rules"
                    hide-details='auto'
                    filled
                >
                </v-text-field>
            </v-col>
            <v-col class="pl-0">
                <v-select
                    v-model="unit"
                    :items="units"
                    item-text="text"
                    item-value="val"
                    hide-details
                    filled
                    :menu-props="{ top: false, offsetY: true}"
                >
                    <template v-slot:append-outer>
                        <UndoButton v-show="dirty" v-on="$listeners" class="mt-4"/>
                        <HintButton v-if="property.hint" v-on="$listeners" class="mt-4"/>
                    </template>
                    <template v-slot:append>
                        <v-icon>mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </v-col>
        </v-row>
    </v-input>
</template>

<script>
import HintButton from '@/components/inputs/subcomponents/HintButton.vue'
import UndoButton from '@/components/inputs/subcomponents/UndoButton.vue'
export default {
    components: { 
        HintButton, 
        UndoButton
    },
    props: {
        featureName: {
            type: String,
            required: true
        },  
        propertyGroupName: {
            type: String,
        },
        propertyName: {
            type: String,
            required: true
        },
        property: {
            type: Object,
            required: true
        },
        dirty: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        rules() {
            return [
                v => (v !== null && v !== '')  || this.$t('Rules.nullable'),
                v => v >= 0 || this.$t('Rules.minValue', [0]),
                v => v <= 127 || this.$t('Rules.maxValue', [127])
            ]
        },
        value: {
            get() {
                let value = this.$store.getters.property(this.featureName, this.propertyGroupName, this.propertyName)
                return /\d/.test(value) ? parseInt(value) : ''   // only parse if value is a digit
            },
            set(newValue) {
                let payload = {
                    featureName: this.featureName,
                    propertyGroupName: this.propertyGroupName,
                    propertyName: this.propertyName,
                    newValue: newValue + this.unit
                }
                this.$store.dispatch("setProperty", payload)
            }
        },
        unit: {
            get() {
                return this.$store.getters.property(this.featureName, this.propertyGroupName, this.propertyName).slice(-1)
            },
            set(newValue) {
                let payload = {
                    featureName: this.featureName,
                    propertyGroupName: this.propertyGroupName,
                    propertyName: this.propertyName,
                    newValue: this.value + newValue
                }
                this.$store.dispatch("setProperty", payload)
            }
        },
        units() {
            return [
                {
                    text: this.$t('Input.time.minutes'),
                    val: 'm'    
                }, 
                {
                    text: this.$t('Input.time.hours'),
                    val: 'h'
                }
            ]
        }
    }
}
</script>