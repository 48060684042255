<template>
    <v-switch
        v-model="configProperty"
        :label="translate(property.title)"
        hide-details="auto"
        class="my-0 pl-1"
        inset
    >
        <template v-slot:append>
            <UndoButton v-show="dirty" v-on="$listeners"/>
            <HintButton v-if="property.hint" v-on="$listeners"/>
        </template>
    </v-switch>
</template>

<script>
import HintButton from '@/components/inputs/subcomponents/HintButton.vue'
import UndoButton from '@/components/inputs/subcomponents/UndoButton.vue'
export default {
    components: { 
        HintButton, 
        UndoButton
    },
    props: {
        featureName: {
            type: String,
            required: true
        },  
        propertyGroupName: {
            type: String,
        },
        propertyName: {
            type: String,
            required: true
        },
        property: {
            type: Object,
            required: true
        },
        dirty: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        configProperty: {
            get() {
                return this.$store.getters.property(this.featureName, this.propertyGroupName, this.propertyName)    
            },
            set(newValue) {
                let payload = {
                    featureName: this.featureName,
                    propertyGroupName: this.propertyGroupName,
                    propertyName: this.propertyName,
                    newValue: newValue ? 1 : 0
                }
                this.$store.dispatch("setProperty", payload)
            }
        }
    }
}
</script>