<template>
    <v-text-field
        v-model="configProperty"
        :label="translate(property.title)"
        type="text"
        :suffix="property.unit"
        hide-details= 'auto'
        :rules="rules"
        filled
        @keydown.enter.prevent
    >
        <template v-slot:append-outer>
            <UndoButton v-show="dirty" v-on="$listeners" class="mt-4"/>
            <HintButton v-if="property.hint" v-on="$listeners" class="mt-4"/>
        </template>
    </v-text-field>
</template>

<script>
import HintButton from '@/components/inputs/subcomponents/HintButton.vue'
import UndoButton from '@/components/inputs/subcomponents/UndoButton.vue'
import generateRules from '@/utils/generateRules.js'

export default {
    components: { 
        HintButton, 
        UndoButton
    },
    props: {
        featureName: {
            type: String,
            required: true
        },  
        propertyGroupName: {
            type: String,
        },
        propertyName: {
            type: String,
            required: true
        },
        property: {
            type: Object,
            required: true
        },
        dirty: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        configProperty: {
            get() {
                return this.$store.getters.property(this.featureName, this.propertyGroupName, this.propertyName).toString()    
            },
            set(newValue) {
                if(this.property.data_type == 'hex') {
                    newValue = newValue.toUpperCase()
                }
                let payload = {
                    featureName: this.featureName,
                    propertyGroupName: this.propertyGroupName,
                    propertyName: this.propertyName,
                    newValue
                }
                this.$store.dispatch("setProperty", payload)
            }
        },
        rules() {
            return generateRules(this.property)
        }
    }
}
</script>