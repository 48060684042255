<template>
    <v-select
        v-model="configProperty"
        :label="translate(property.title)"
        :items="keys"
        hide-details='auto'
        filled
        :menu-props="{ top: false, offsetY: true}"        
    >
        <template v-slot:item="data">
            {{ getValue(data.item) }}
        </template>
        <template v-slot:selection="data">
            {{ getValue(data.item) }}
        </template>
        <template v-slot:append-outer>
            <UndoButton v-show="dirty" v-on="$listeners" class="mt-4"/>
            <HintButton v-if="property.hint" v-on="$listeners" class="mt-4"/>
        </template>
        <template v-slot:append>
            <v-icon>mdi-chevron-down</v-icon>
        </template>
    </v-select>
</template>

<script>
import HintButton from '@/components/inputs/subcomponents/HintButton.vue'
import UndoButton from '@/components/inputs/subcomponents/UndoButton.vue'
export default {
    components: { 
        HintButton, 
        UndoButton
    },
    props: {
        featureName: {
            type: String,
            required: true
        },  
        propertyGroupName: {
            type: String,
        },
        propertyName: {
            type: String,
            required: true
        },
        property: {
            type: Object,
            required: true
        },
        dirty: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        keys() {
            return Object.keys(this.property.options).map(Number)
        },
        configProperty: {
            get() {
                return this.$store.getters.property(this.featureName, this.propertyGroupName, this.propertyName)    
            },
            set(newValue) {
                let payload = {
                    featureName: this.featureName,
                    propertyGroupName: this.propertyGroupName,
                    propertyName: this.propertyName,
                    newValue
                }
                this.$store.dispatch("setProperty", payload)
            }
        }
    },
    methods: {
        getValue(item) {
            let value = this.property.options[item]
            if(typeof value == 'object') {
                value = this.translate(value)
            }
            if(this.property.unit) {
                value += this.property.unit
            }
            return value
        }
    }
}
</script>